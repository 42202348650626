import { IHomePageCard } from "../Types/HomePageCard";
import { homePageCards } from "../data/HomePageCards";

export const filterHomePageCardsUtil = (
  userGroups: string[]
): IHomePageCard[] => {
  if (userGroups.includes("Admin")) {
    return homePageCards;
  }
  const filteredCards = homePageCards.filter((item) =>
    userGroups.some((group) => item.groups.includes(group))
  );
  return filteredCards;
};
